import React from "react";
import { ButtonGroup } from "../../components/ButtonGroup";
import { ElementExampleLeoBrain } from "../../components/ElementExampleLeoBrain";
import { ExampleLeoBrain } from "../../components/ExampleLeoBrain";
import { FourExampleLeoBrain } from "../../components/FourExampleLeoBrain";
import { PropertyDefaultWrapper } from "../../components/PropertyDefaultWrapper";
import { StyleTonalStateWrapper } from "../../components/StyleTonalStateWrapper";
import "./style.css";
import headerleoimage from '../../static/img/mask-group-2.png';
import bigleopage1 from '../../static/img/mask-group-1.png';
import bigleobrain from '../../static/img/image-1-1.png';
import tiktok from '../../static/img/image-3.png';
import gads from '../../static/img/image-4.png';
import leofacemedium from '../../static/img/mask-group.png';
import whale from '../../static/img/image-5.png';

export const Page = () => {
  return (
    <div className="page">
      <div className="div-2">
        <div className="frame">
          <div className="frame-wrapper">
            <div className="div-wrapper">
              <div className="overlap-group-wrapper">
                <div className="overlap-group">
                  <div className="scroll-down-on">
                    <p className="p">See what Leonardo can do for you.</p>
                  </div>
                  <ButtonGroup
                    align="justify"
                    buttonDivClassName="button-group-2"
                    buttonLabel="Get a Demo"
                    buttonLabelSecondary="Sign In"
                    buttonEndSecondary={true} 
                    buttonEndHref="https://calendly.com/connor-fibonaccipartner/product-demo" 
                    buttonEndHrefSecondary="https://leonardo.fibonaccipartner.com/" 
                    buttonEndTarget="_blank" 
                    buttonEndTargetSecondary="_self" 
                    buttonVariantPrimaryClassName="design-component-instance-node"
                    className="top-button-group-instance"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="div-3">
          <div className="frame-2">
            <div className="page-wrapper">
              <div className="div-4">
                <img className="DALLE" alt="Dalle" src={bigleobrain} />
                <p className="text-wrapper-2">
                  Getting started with Leonardo is simple. Connect your marketing data, and he’ll take care of the rest—turning insights into action and launching campaigns that expand impact while maximizing efficiency.
                </p>
                <div className="group">
                  <div className="overlap-group-2">
                    <div className="ellipse" />
                    <div className="group-2" />
                    <div className="group-3" />
                    <div className="group-4" />
                    <div className="group-5" />
                    <div className="group-6" />
                    <img className="image" alt="Image" src={tiktok} />
                    <img className="img" alt="Image" src={gads} />
                    <img className="mask-group" alt="Mask group" src={leofacemedium} />
                    <img className="image-2" alt="Image" src={whale} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="div-3">
            <div className="frame-3">
              <img className="mask-group-2" alt="Mask group" src={bigleopage1} />
              <div className="frame-4">
                <div className="frame-5">
                  <div className="frame-6">
                    <div className="frame-7">
                      <div className="group-7">
                        <div className="group-8">
                          <div className="text-wrapper-3">Fibonacci</div>
                        </div>
                        <img className="mask-group-3" alt="Mask group" src={headerleoimage} />
                      </div>
                      <ButtonGroup
                        align="justify"
                        buttonLabel="Get a Demo"
                        buttonStart={false}
                        buttonEndHref="https://calendly.com/connor-fibonaccipartner/product-demo"
                        buttonEndTarget="_blank" 
                        buttonVariantPrimaryClassName="button-group-4"
                        className="button-group-3"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <p className="meet-leonardo-your">
                Meet Leonardo <br /> your brand’s marketing assistant that never clocks out
              </p>
              <p className="text-wrapper-4">Unlock insights, automate tasks, and grow faster</p>
              <StyleTonalStateWrapper
                className="button-instance top-demo-button"
                labelText="Get a Demo"
                labelTextClassName="button-2"
                showIcon={false}
                stateProp="enabled"
                style="filled"
                href="https://calendly.com/connor-fibonaccipartner/product-demo"
              />

              <div className="div-5">
                <img
                  className="DALLE"
                  alt="Dalle"
                  src="/img/dall-e-2024-09-21-19-42-12-a-detailed-black-and-white-sketch-p-1.png"
                />
                <div className="overlap-group-3">
                  <p className="text-wrapper-5">
                    Leonardo is an AI-powered marketing assistant that continuously learns from your business and industry, uncovering insights and crafting targeted strategies to transform your marketing impact.
                  </p>
                  <p className="with-leonardo-AI-isn">
                    <span className="span">With Leonardo, AI isn’t just communicating—</span>
                    <span className="text-wrapper-6">it’s doing</span>
                  </p>
                </div>
                <ElementExampleLeoBrain className="two-example-leo-brain" property1="default" />
                <PropertyDefaultWrapper className="three-example-leo-brain" property1="variant-10" />
                <FourExampleLeoBrain className="element-example-leo-brain-instance" property1="variant-4" />
                <ExampleLeoBrain className="example-leo-brain-1" property1="variant-11" />
                <img className="image-3" alt="Image" src={bigleobrain} />
              </div>
            </div>

            <div className="frame-8">
              <div className="frame-wrapper center-content">
                <div className="div-wrapper">
                  <div className="frame-wrapper">
                    <div className="div-wrapper">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group">
                          <div className="scroll-down-on">
                            <p className="p">See what Leonardo can do for you.</p>
                          </div>
                          <StyleTonalStateWrapper
                            className="button-instance bottom-demo-button"
                            labelText="Get a Demo"
                            labelTextClassName="button-2"
                            showIcon={false}
                            stateProp="enabled"
                            style="filled"
                            href="https://calendly.com/connor-fibonaccipartner/product-demo"
                          />
                          <div className="purple-overlay"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
