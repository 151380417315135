import PropTypes from "prop-types";
import React, { useReducer } from "react";
import "./style.css";

export const Button = ({
  hasIconStart = false,
  hasIconEnd = false,
  label = "Button",
  variant,
  stateProp,
  size,
  className,
  divClassName,
  href,              // Add href prop for the link
  target = "_self"   // Default target for link behavior
}) => {
  const [state, dispatch] = useReducer(reducer, {
    variant: variant || "primary",
    state: stateProp || "default",
    size: size || "medium",
  });

  // Render button content
  const ButtonContent = (
    <button
      className={`button ${state.state} ${state.size} ${state.variant} ${className}`}
      onMouseLeave={() => dispatch("mouse_leave")}
      onMouseEnter={() => dispatch("mouse_enter")}
    >
      <div className={`text-wrapper ${divClassName}`}>{label}</div>
    </button>
  );

  // If href is provided, wrap ButtonContent in an anchor tag
  return href ? (
    <a 
      href={href} 
      target={target} 
      rel={target === "_blank" ? "noopener noreferrer" : undefined} 
      className="button-link"
    >
      {ButtonContent}
    </a>
  ) : (
    ButtonContent
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return { ...state, state: "hover" };
    case "mouse_leave":
      return { ...state, state: "default" };
    default:
      return state;
  }
}

Button.propTypes = {
  hasIconStart: PropTypes.bool,
  hasIconEnd: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "neutral", "subtle"]),
  stateProp: PropTypes.oneOf(["disabled", "hover", "default"]),
  size: PropTypes.oneOf(["medium", "small"]),
  href: PropTypes.string,                // Link URL
  target: PropTypes.oneOf(["_self", "_blank"]) // Target for link behavior
};
