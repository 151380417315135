import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import "./style.css";

const variants = [
  "default",
  "variant-2",
  "variant-3",
  "variant-4",
  "variant-5",
  "variant-6",
  "variant-7",
  "variant-8",
  "variant-9",
  "variant-10",
  "variant-11",
  "variant-12",
  "variant-13",
];

export const ExampleLeoBrain = ({ className }) => {
  const [property1, setProperty1] = useState("default");

  useEffect(() => {
    const interval = setInterval(() => {
      setProperty1((prev) => {
        const currentIndex = variants.indexOf(prev);
        const nextIndex = (currentIndex + 1) % variants.length;
        return variants[nextIndex];
      });
    }, 3000); // Change every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div className={`example-leo-brain ${className}`}>
      <div className={`why-have-our-sales property-1-5-${property1}`}>
        {property1 === "default" && (
          <p className="text-wrapper-7">Why have our sales stagnated this Q?</p>
        )}
        {property1 === "variant-2" && <>Analyzing cross-platform sales trends</>}
        {property1 === "variant-3" && <>Identifying underperforming product categories</>}
        {property1 === "variant-4" && <>Segmenting customers by behavior</>}
        {property1 === "variant-5" && (
          <p className="text-wrapper-7">Comparing marketing spend with ROI</p>
        )}
        {property1 === "variant-6" && <>Monitoring competitor activities</>}
        {property1 === "variant-7" && (
          <p className="text-wrapper-7">Evaluating website traffic and conversions</p>
        )}
        {property1 === "variant-8" && (
          <p className="text-wrapper-7">Scanning customer feedback and reviews</p>
        )}
        {property1 === "variant-9" && <>Reviewing ad campaign engagement</>}
        {property1 === "variant-10" && <>Simulating alternative pricing strategies</>}
        {property1 === "variant-11" && <>Forecasting potential outcomes</>}
        {property1 === "variant-12" && <>Discarding statistically insignificant discoveries</>}
        {property1 === "variant-13" && (
          <p className="text-wrapper-7">
            Sending presentation with next steps to Webb &amp; Steve
          </p>
        )}
      </div>
    </div>
  );
};

ExampleLeoBrain.propTypes = {
  className: PropTypes.string,
};
