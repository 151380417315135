import PropTypes from "prop-types";
import React from "react";
import { Button } from "../Button";
import "./style.css";

export const ButtonGroup = ({
  buttonEnd = true,
  buttonStart = true,
  buttonEndSecondary = true,
  align,
  className,
  buttonVariantPrimaryClassName,
  buttonLabel = "Get a Demo",
  buttonLabelSecondary = "Sign In",
  buttonDivClassName,
  buttonStartHref,
  buttonEndHref = "https://calendly.com/connor-fibonaccipartner/product-demo",
  buttonEndHrefSecondary = "https://leonardo.fibonaccipartner.com/",
  buttonEndTarget = "_blank",
  buttonEndTargetSecondary = "_self",
}) => {
  return (
    <div className={`button-group ${align} ${className}`}>
      {/* Place the secondary button first */}
      {buttonEndSecondary && (
        <Button
          className={`${buttonVariantPrimaryClassName} secondary-button`}
          divClassName={buttonDivClassName}
          label={buttonLabelSecondary}
          size="medium"
          stateProp="default"
          variant="primary"
          href={buttonEndHrefSecondary}
          target={buttonEndTargetSecondary}
        />
      )}

      {/* Place the main button second */}
      {buttonEnd && (
        <Button
          className={`${buttonVariantPrimaryClassName} primary-button`}
          divClassName={buttonDivClassName}
          label={buttonLabel}
          size="medium"
          stateProp="default"
          variant="primary"
          href={buttonEndHref}
          target={buttonEndTarget}
        />
      )}
    </div>
  );
};

ButtonGroup.propTypes = {
  buttonEnd: PropTypes.bool,
  buttonStart: PropTypes.bool,
  buttonEndSecondary: PropTypes.bool,
  align: PropTypes.oneOf(["start", "center", "justify", "stack", "end"]),
  buttonLabel: PropTypes.string,
  buttonLabelSecondary: PropTypes.string,
  buttonStartHref: PropTypes.string,
  buttonEndHref: PropTypes.string,
  buttonEndHrefSecondary: PropTypes.string,
  buttonEndTarget: PropTypes.string,
  buttonEndTargetSecondary: PropTypes.string,
};
