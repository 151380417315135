import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import "./style.css";

// Array of variants for cycling
const variants = [
  "default",
  "variant-2",
  "variant-3",
  "variant-4",
  "variant-5",
  "variant-6",
  "variant-7",
  "variant-8",
  "variant-9",
  "variant-10",
  "variant-11",
  "variant-12",
  "variant-13",
];

export const FourExampleLeoBrain = ({ className }) => {
  const [property1, setProperty1] = useState("default");

  useEffect(() => {
    const interval = setInterval(() => {
      setProperty1((prev) => {
        const currentIndex = variants.indexOf(prev);
        const nextIndex = (currentIndex + 1) % variants.length;
        return variants[nextIndex];
      });
    }, 2000); // Cycle every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div
      className={`four-example-leo-brain property-1-3-${property1} ${className}`}
    >
      <div className="how-can-we-improve">
        {property1 === "default" && (
          <p className="text-wrapper-7">
            How can we improve engagement on organic channels?
          </p>
        )}
        {property1 === "variant-2" && (
          <p className="text-wrapper-7">
            Tracking audience sentiment in comments
          </p>
        )}
        {property1 === "variant-3" && (
          <p className="text-wrapper-7">
            Analyzing top-performing posts across competitors
          </p>
        )}
        {property1 === "variant-4" && (
          <p className="text-wrapper-7">
            Collaborating with micro-influencers for reach
          </p>
        )}
        {property1 === "variant-5" && (
          <p className="text-wrapper-7">
            Generating engaging content ideas based on trends
          </p>
        )}
        {property1 === "variant-6" && (
          <p className="text-wrapper-7">
            Testing A/B versions of posts in real-time
          </p>
        )}
        {property1 === "variant-7" && (
          <p className="text-wrapper-7">Identifying which formats resonate</p>
        )}
        {property1 === "variant-8" && (
          <p className="text-wrapper-7">
            Triggering interaction through polls and challenges
          </p>
        )}
        {property1 === "variant-9" && (
          <p className="text-wrapper-7">
            Launching time-sensitive giveaways or contests
          </p>
        )}
        {property1 === "variant-10" && (
          <p className="text-wrapper-7">
            Automating content scheduling for peak engagement times
          </p>
        )}
        {property1 === "variant-11" && (
          <p className="text-wrapper-7">Generating a prioritized issue list</p>
        )}
        {property1 === "variant-12" && (
          <p className="text-wrapper-7">
            Tracking posts and surfacing viral indicators early
          </p>
        )}
        {property1 === "variant-13" && (
          <p className="text-wrapper-7">
            Reporting back to Caroline on progress
          </p>
        )}
      </div>
    </div>
  );
};

FourExampleLeoBrain.propTypes = {
  className: PropTypes.string,
};
