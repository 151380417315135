import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import "./style.css";

const variants = [
  "default",
  "variant-2",
  "variant-3",
  "variant-4",
  "variant-5",
  "variant-6",
  "variant-7",
  "variant-8",
  "variant-9",
  "variant-10",
  "variant-11",
  "variant-12",
  "variant-13",
];

export const PropertyDefaultWrapper = ({ className }) => {
  const [property1, setProperty1] = useState("default");

  useEffect(() => {
    const interval = setInterval(() => {
      setProperty1((prev) => {
        const currentIndex = variants.indexOf(prev);
        const nextIndex = (currentIndex + 1) % variants.length;
        return variants[nextIndex];
      });
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div
      className={`property-default-wrapper property-1-1-${property1} ${className}`}
    >
      <div className="where-are-our-weak">
        {property1 === "default" && (
          <p className="text-wrapper-7">
            Where are our weak points on the customer journey?
          </p>
        )}
        {property1 === "variant-2" && (
          <p className="text-wrapper-7">
            Mapping each step of the customer journey
          </p>
        )}
        {property1 === "variant-3" && (
          <p className="text-wrapper-7">
            Surveying recent customers for pain points
          </p>
        )}
        {property1 === "variant-4" && (
          <p className="text-wrapper-7">
            Tracking drop-off points in the funnel
          </p>
        )}
        {property1 === "variant-5" && (
          <p className="text-wrapper-7">Analyzing support ticket trends</p>
        )}
        {property1 === "variant-6" && (
          <p className="text-wrapper-7">
            Measuring time spent at each touchpoint
          </p>
        )}
        {property1 === "variant-7" && (
          <p className="text-wrapper-7">
            Identifying friction in checkout processes
          </p>
        )}
        {property1 === "variant-8" && (
          <p className="text-wrapper-7">
            Simulating the journey as a new customer
          </p>
        )}
        {property1 === "variant-9" && (
          <p className="text-wrapper-7">
            Auditing customer service response times
          </p>
        )}
        {["variant-10", "variant-11"].includes(property1) && (
          <p className="text-wrapper-7">Generating a prioritized issue list</p>
        )}
        {property1 === "variant-12" && (
          <p className="text-wrapper-7">Creating a report for the CX team</p>
        )}
        {property1 === "variant-13" && (
          <p className="text-wrapper-7">
            Scheduling a cross-functional meeting to address issues
          </p>
        )}
      </div>
    </div>
  );
};

PropertyDefaultWrapper.propTypes = {
  className: PropTypes.string,
};
