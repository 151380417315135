/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { Icon10 } from "../../icons/Icon10";
import { Icon20 } from "../../icons/Icon20";
import "./style.css";

export const StyleTonalStateWrapper = ({
  labelText = "Label",
  style,
  stateProp,
  showIcon,
  className,
  labelTextClassName,
  href, // Add href for link functionality
  target = "_self", // Default target behavior (e.g., opens in same tab)
}) => {
  const [state, dispatch] = useReducer(reducer, {
    style: style || "filled",
    state: stateProp || "enabled",
    showIcon: showIcon || false,
  });

  // Define button content for reuse
  const ButtonContent = (
    <div
      className={`style-tonal-state-wrapper ${state.style} state-0-${state.state} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >
      <div
        className={`state-layer style-${state.style} state-1-${state.state} show-icon-${state.showIcon}`}
      >
        {((state.showIcon &&
          state.state === "disabled" &&
          state.style === "elevated") ||
          (state.showIcon &&
            state.state === "disabled" &&
            state.style === "text") ||
          (state.showIcon &&
            state.state === "disabled" &&
            state.style === "tonal") ||
          (state.showIcon && state.state === "enabled") ||
          (state.showIcon && state.state === "focused") ||
          (state.showIcon && state.state === "hovered") ||
          (state.showIcon && state.state === "pressed")) && (
          <Icon10
            className="icon"
            color={
              state.state === "disabled"
                ? "#1D1B20"
                : (state.state === "enabled" && state.style === "tonal") ||
                  (state.state === "focused" && state.style === "tonal") ||
                  (state.state === "hovered" && state.style === "tonal") ||
                  (state.state === "pressed" && state.style === "tonal")
                  ? "#4A4459"
                  : state.style === "filled"
                    ? "white"
                    : "#65558F"
            }
            opacity={state.state === "disabled" ? "0.38" : undefined}
          />
        )}

        {!state.showIcon && (
          <div className={`label-text ${labelTextClassName}`}>{labelText}</div>
        )}

        {state.showIcon &&
          state.state === "disabled" &&
          ["filled", "outlined"].includes(state.style) && (
            <Icon20 className="icon" />
          )}

        {state.showIcon && <div className="div">{labelText}</div>}
      </div>
    </div>
  );

  // Conditionally render the wrapper as an anchor if href is provided
  return href ? (
    <a href={href} target={target} rel="noopener noreferrer">
      {ButtonContent}
    </a>
  ) : (
    ButtonContent
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hovered",
      };
    case "mouse_leave":
      return {
        ...state,
        state: "enabled",
      };
    default:
      return state;
  }
}

StyleTonalStateWrapper.propTypes = {
  labelText: PropTypes.string,
  style: PropTypes.oneOf(["filled", "tonal", "elevated", "text", "outlined"]),
  stateProp: PropTypes.oneOf([
    "enabled",
    "focused",
    "pressed",
    "hovered",
    "disabled",
  ]),
  showIcon: PropTypes.bool,
  href: PropTypes.string, // Prop type for link URL
  target: PropTypes.oneOf(["_self", "_blank"]), // Prop type for link behavior
};
