import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import "./style.css";

const variants = [
  "default",
  "variant-2",
  "variant-3",
  "variant-4",
  "variant-5",
  "variant-6",
  "variant-7",
  "variant-8",
  "variant-9",
  "variant-10",
  "variant-11",
  "variant-12",
  "variant-13",
];

export const ElementExampleLeoBrain = ({ className }) => {
  const [property1, setProperty1] = useState("default");

  useEffect(() => {
    const interval = setInterval(() => {
      setProperty1((prev) => {
        const currentIndex = variants.indexOf(prev);
        const nextIndex = (currentIndex + 1) % variants.length;
        return variants[nextIndex];
      });
    }, 3500); // Change every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div
      className={`element-example-leo-brain property-1-${property1} ${className}`}
    >
      <div className="what-should-our">
        {property1 === "default" && (
          <p className="text-wrapper-7">
            What should our content strategy look like next year?
          </p>
        )}
        {property1 === "variant-2" && (
          <p className="text-wrapper-7">
            Crowdsourcing content ideas from customer feedback
          </p>
        )}
        {property1 === "variant-3" && (
          <p className="text-wrapper-7">
            Experimenting with different content formats
          </p>
        )}
        {property1 === "variant-4" && (
          <p className="text-wrapper-7">
            Interviewing key stakeholders in the business
          </p>
        )}
        {property1 === "variant-5" && (
          <p className="text-wrapper-7">
            Collecting influencer opinions on content trends
          </p>
        )}
        {property1 === "variant-6" && (
          <p className="text-wrapper-7">Benchmarking against high-growth brands</p>
        )}
        {property1 === "variant-7" && (
          <p className="text-wrapper-7">
            Analyzing missed opportunities in current content
          </p>
        )}
        {property1 === "variant-8" && (
          <p className="text-wrapper-7">
            Evaluating which platforms drive conversions
          </p>
        )}
        {property1 === "variant-9" && (
          <p className="text-wrapper-7">Predicting future industry shifts</p>
        )}
        {property1 === "variant-10" && (
          <p className="text-wrapper-7">Developing a shortlist of key themes</p>
        )}
        {property1 === "variant-11" && (
          <p className="text-wrapper-7">
            Automating the first draft of a content calendar
          </p>
        )}
        {property1 === "variant-12" && (
          <p className="text-wrapper-7">
            Publishing sample content for A/B testing
          </p>
        )}
        {property1 === "variant-13" && (
          <p className="text-wrapper-7">
            Coordinating a strategy session with the leadership team
          </p>
        )}
      </div>
    </div>
  );
};

ElementExampleLeoBrain.propTypes = {
  className: PropTypes.string,
};
